export const palette = {
  light: '#FFFFFF',
  dark: '#111111',
  pink: '#F54A90',
  pinkGradientEnd: '#FF72AC',
  dustyPink: '#A17688',
  powderPink: '#FFF6FB',
  darkGrey: '#353A3F',
  grey: '#727579',
  lightGrey: '#AEB0B2',
  backgroundGrey: '#F4F3F3',
  backgroundLightGrey: '#C4F3F320',
  transparent: '#00000000',
  secondary: '#22d3ee',
  brandingBlue: '#108FEB',
};
