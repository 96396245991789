import { FC, memo, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { NiceModalHandler } from '@ebay/nice-modal-react';
import { Formik, FormikHelpers } from 'formik';

import { OCCUPANCY_TYPE_TO_LABEL_MAP } from 'constants/application';
import { PropertyType } from 'enums/application';
import { EditPreApprovalRequestType, EditPreApprovalResponseStatus } from 'enums/preApproval';
import { getApiError } from 'utils/api';
import { checkForTbdValue } from 'utils/common';

import { useEditPreApprovalMutation, useLazyCheckStateLicenseQuery } from 'services/applyAPI';
import {
  GetPotentialLoanOptionsPayload,
  GetPotentialLoanOptionsResponse,
} from 'services/applyAPI/types';
import { ApplyMutationType } from 'types/common';
import { EditPreApprovalLetterParams } from 'types/preApproval';

import CurrencyField from 'components/@fields/CurrencyField/CurrencyField';
import TextField from 'components/@fields/TextField';
import PropertyTypeWithUnitsFields from 'components/@formFields/PropertyTypeWithUnitsFields';
import FormikAddress from 'components/FormikAddressFields';

import { ManageModalProps, Step } from '../@shared';

import { getSchema } from './EditData.validationSchema';
import { APIErrorMessage, Form } from './EditDataForm.styles';
import { AddressWrapper, Inputs } from './EditDataForm.styles';

interface BottomContentProps {
  isSubmitting: boolean;
  dirty: boolean;
}

export interface EditDataFormProps extends ManageModalProps {
  setStep: (step: Step) => void;
  setPreapprovalData?: (data: EditPreApprovalLetterParams) => void;
  getLoanOptions?: ApplyMutationType<
    GetPotentialLoanOptionsPayload,
    GetPotentialLoanOptionsResponse
  >;
  topContent?: JSX.Element;
  bottomContent?: ({ isSubmitting, dirty }: BottomContentProps) => JSX.Element;
  modal?: NiceModalHandler<Record<string, unknown>>;
  // for purchase agreement
  formId?: string;
  isTbd?: boolean;
  onSubmitPurchaseAgreement?: (fd: FormData, data: EditPreApprovalLetterParams) => void;
  getFormDataForPA?: (data: EditPreApprovalLetterParams, loanOptionId?: string) => FormData;
}
const EditDataForm: FC<EditDataFormProps> = ({
  address,
  propertyValue,
  downPayment,
  propertyUsage,
  propertyType,
  numberOfUnits,
  setStep,
  applicationId,
  getLoanOptions,
  setPreapprovalData,
  topContent,
  bottomContent,
  formId,
  onSubmitPurchaseAgreement,
  getFormDataForPA,
  modal,
  isTbd,
}) => {
  const [editPreApproval, { error }] = useEditPreApprovalMutation();
  const [checkStateLicense] = useLazyCheckStateLicenseQuery();

  const APIError = getApiError(error);
  const isPurchaseAgreement = !!onSubmitPurchaseAgreement;

  const initialValues = useMemo(
    () => ({
      propertyValue: propertyValue ?? '',
      propertyType: propertyType || '',
      downPaymentValue: downPayment,
      propertyUsage: OCCUPANCY_TYPE_TO_LABEL_MAP[propertyUsage],
      numberOfUnits,
      country: 'USA',
      state: '',
      zip: '',
      city: '',
      county: '',
      ...address,
      street: (isTbd && checkForTbdValue(address?.street || '') ? '' : address?.street) || '',
      // when editing pre-approval from apply, signalR receives null in unit field
      unit: address?.unit || '',
    }),
    [isTbd, propertyValue, propertyType, downPayment, propertyUsage, numberOfUnits, address],
  );

  const onSubmitPreApproval = useCallback(
    async (
      values: typeof initialValues,
      { setFieldError }: FormikHelpers<typeof initialValues>,
    ) => {
      const isLicensed = await checkStateLicense({ state: values.state || '' }).unwrap();

      if (!isLicensed) {
        setFieldError(
          'state',
          "Unfortunately, we don't provide service in your state at this time.",
        );
        return;
      }

      const isMultiUnit = values.propertyType === PropertyType.MultiUnit;
      const numberOfUnits = isMultiUnit ? values.numberOfUnits : 1;
      const address = {
        city: values.city,
        country: values.country,
        county: values.county,
        state: values.state,
        street: values.street,
        unit: values.unit || null,
        zip: values.zip,
      };
      const editPreApprovalData = {
        address,
        numberOfUnits,
        propertyType: values.propertyType,
        propertyValue: values.propertyValue as number,
        downPaymentValue: values.downPaymentValue,
      };

      if (isPurchaseAgreement && getFormDataForPA) {
        const fd = getFormDataForPA(editPreApprovalData);

        await onSubmitPurchaseAgreement(fd, editPreApprovalData);

        return;
      }

      const isNewPreApproval = !propertyValue && !downPayment;
      const isPropertyValueChanged = !!(propertyValue && +values.propertyValue !== propertyValue);
      const isDownPaymentChanged = !!(
        downPayment && +(values.downPaymentValue as number) !== downPayment
      );
      const isPropertyTypeChanged = values.propertyType !== initialValues.propertyType;
      const isNumberOfUnitsChanged =
        isMultiUnit && values.numberOfUnits !== initialValues.numberOfUnits;

      if (
        isNewPreApproval ||
        isPropertyValueChanged ||
        isPropertyTypeChanged ||
        isDownPaymentChanged ||
        isNumberOfUnitsChanged
      ) {
        const getPotentialLoanOptionsResponse = await getLoanOptions?.({
          applicationId,
          numberOfUnits,
          propertyType: values.propertyType,
          propertyValue: values.propertyValue as number,
          downPaymentValue: values.downPaymentValue as number,
        }).unwrap();

        if (!getPotentialLoanOptionsResponse?.loanOptions?.length) {
          setStep(Step.Error);
          return;
        }

        setPreapprovalData?.(editPreApprovalData);

        const sameProgram = getPotentialLoanOptionsResponse?.selectedLoanOptionId;
        setStep(sameProgram ? Step.SubmitProgram : Step.SelectProgram);
        return;
      }

      const status = await editPreApproval({
        type: EditPreApprovalRequestType.Full,
        applicationId,
        ...editPreApprovalData,
      }).unwrap();

      if (status !== EditPreApprovalResponseStatus.RequestSent) {
        setStep(Step.Error);
        return;
      }

      modal?.remove();
      toast('Pre-Approval Letter is generating. It may take up to 1 minute');
    },
    [
      applicationId,
      checkStateLicense,
      downPayment,
      editPreApproval,
      getFormDataForPA,
      getLoanOptions,
      initialValues.numberOfUnits,
      initialValues.propertyType,
      isPurchaseAgreement,
      modal,
      onSubmitPurchaseAgreement,
      propertyValue,
      setPreapprovalData,
      setStep,
    ],
  );

  return (
    <>
      {topContent}

      <Formik
        initialValues={initialValues}
        validationSchema={getSchema(isPurchaseAgreement)}
        onSubmit={onSubmitPreApproval}
      >
        {({
          values,
          dirty,
          isSubmitting,
          setFieldValue,
          setValues,
          handleChange,
          setFieldTouched,
        }) => (
          <Form $isPurchaseAgreement={isPurchaseAgreement} id={formId}>
            <Inputs>
              <PropertyTypeWithUnitsFields
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                values={values}
              />
              {!isPurchaseAgreement && (
                <TextField disabled label="Property Usage" name="propertyUsage" />
              )}
              <CurrencyField
                isAbsoluteError
                isDecimalScale
                required
                shouldRemoveLeadingZeros
                label="Purchase Price"
                name="propertyValue"
                onChange={handleChange}
              />
              <CurrencyField
                isAbsoluteError
                isDecimalScale
                required
                shouldRemoveLeadingZeros
                label="Down Payment"
                name="downPaymentValue"
                onChange={handleChange}
              />
              <AddressWrapper>
                <FormikAddress
                  handleChange={handleChange}
                  isAbsoluteError={!isPurchaseAgreement}
                  setValues={setValues}
                />
              </AddressWrapper>
            </Inputs>
            {APIError && <APIErrorMessage error={APIError} />}
            {bottomContent?.({ dirty, isSubmitting })}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default memo(EditDataForm);
