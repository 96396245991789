import { getEnvVariable } from 'utils/common';

export const IS_PRODUCTION = getEnvVariable(
  'isProd',
  process.env.NODE_ENV === 'production' && process.env.REACT_APP_IS_PROD,
);
export const IS_DEVELOPMENT =
  process.env.NODE_ENV === 'development' ||
  window.origin.startsWith('http://localhost') ||
  window.origin.startsWith('http://192.168');

export const APP_CONTAINER_ID = 'appContainerId';

export const CLIENT_ID = 'RealtorPortal';
export const REALTOR_ID = 'realtorId';

export const REALTOR_ROLE_NAME = 'internal.realtor';

export const REALTOR_LOAN_OFFICER_CLAIM = 'realtorPortal.extendedFunctionality';

// match safari browser on desktop and all browsers on IOS
export const IS_SAFARI = !!navigator.vendor.match(/apple/i);

export const APP_INSIGHTS_INSTRUMENTARY_KEY = getEnvVariable(
  'appInsightsInstrumentaryKey',
  process.env.APPINSIGHTS_INSTRUMENTATIONKEY,
);

export const APPLICATIONINSIGHTS_CONNECTION_STRING = getEnvVariable(
  'appInsightsConnectionString',
  process.env.APPLICATIONINSIGHTS_CONNECTION_STRING,
);
