import {
  AppInsightsAggregator,
  Logger,
  LoggerSettings,
  LoggingLevel,
  LogLevel,
} from '@interfirst/utils';

import { APPLICATIONINSIGHTS_CONNECTION_STRING, IS_PRODUCTION } from 'constants/common';

export const commonLoggerSettings = {
  expand: {
    [LogLevel.INFO]: true,
    [LogLevel.LOG]: true,
    [LogLevel.WARN]: true,
    [LogLevel.ERROR]: true,
  },
  loggingLevel: IS_PRODUCTION ? LoggingLevel.ERROR : LoggingLevel.VERBOSE,
  internalLogger: window.console,
};

const isConnectionStringExist =
  APPLICATIONINSIGHTS_CONNECTION_STRING && APPLICATIONINSIGHTS_CONNECTION_STRING !== 'undefined';

export const aiAggregator = isConnectionStringExist
  ? new AppInsightsAggregator({
      disableTelemetry: process.env.DISABLE_AI_LOAD === 'true',
      tags: { 'ai.cloud.role': 'Realtors' },
      connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING as string,
      correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
      enableCorsCorrelation: true,
      // instead of passing history for react-router v6
      enableAutoRouteTracking: true,
    })
  : undefined;

export const externalLogger = new Logger(
  'GLOBAL',
  new LoggerSettings({
    ...commonLoggerSettings,
    aggregator: aiAggregator,
  }),
);

export const handleSignalRError = (error: unknown) => {
  if (error instanceof Error) {
    externalLogger.error(error.message);
  } else {
    externalLogger.error('signalR Error', JSON.stringify(error));
  }
};
