import {
  AuthInitiateType,
  AuthUserRoles,
  AuthUserTypes,
  InitSignInByEmailProps,
  InitSignInViaPhoneProps,
} from '@interfirst/utils';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CLIENT_ID, REALTOR_ID, REALTOR_ROLE_NAME } from 'constants/common';
import { authQuery, getDomainName } from 'utils/api';
import { authClient, prepareHeaders } from 'utils/auth';
import { aiAggregator } from 'utils/logger';

import { setCreateRequestAuthData, setRedirectUrl, signIn } from 'store/slices/auth';

import {
  CompanyIdResponse,
  CreateSignInRequestResponse,
  CreateSignUpRequestPayload,
  GetUserPayload,
  GetUserResponse,
  GetUserResponseFromServer,
} from './types';

type CreatSignInRequestPayload = InitSignInViaPhoneProps | InitSignInByEmailProps;

const prepareError = (e: unknown): FetchBaseQueryError => {
  if (e && typeof e === 'object' && 'message' in e && typeof e?.message === 'string') {
    return {
      status: 'CUSTOM_ERROR',
      error: e.message,
      data: e.message,
    };
  }
  return e as FetchBaseQueryError;
};

const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: authQuery,
  refetchOnMountOrArgChange: true,
  endpoints: build => ({
    // ---- Sign In
    createSignInRequest: build.mutation<CreateSignInRequestResponse, CreatSignInRequestPayload>({
      queryFn: async params => {
        try {
          const data = await authClient.initSignInRequest(params);
          return { data };
        } catch (e) {
          return { error: prepareError(e) };
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(
          ({ data: { expirationDate, confirmationUrl, confirmationCode, requestId } }) => {
            dispatch(
              setCreateRequestAuthData({
                requestId,
                confirmationCode,
                expirationDate,
                ...(confirmationUrl && { redirectUrl: confirmationUrl }),
              }),
            );
          },
        );
      },
    }),
    generateSignInToken: build.mutation<
      void,
      { code: string; requestId: string; isSignUp?: boolean }
    >({
      queryFn: async ({ code, requestId, isSignUp }, _queryApi, _extraOptions, authQuery) => {
        try {
          if (isSignUp) {
            await authClient.sendSignUpVerificationCode({
              verificationCode: code,
              requestId,
            });
          } else {
            await authClient.sendVerificationCode({
              verificationCode: code,
              requestId,
            });
          }

          const authContext = await authClient.getAuthorizationContext();

          const { roles, company, user } = authContext;
          const { id: userId } = user;

          if (userId) {
            localStorage.setItem(REALTOR_ID, userId);
            aiAggregator?.setUserContext({ authenticatedUserId: userId, storeInCookie: true });
          }

          const isNoRealtorRole = !roles.some(({ name }) => name === REALTOR_ROLE_NAME);

          if (isNoRealtorRole) {
            // assign role to user
            const assignRoleResponse = await authQuery({
              url: `companies/${company.id}/users/${userId}/role-assignments`,
              method: 'POST',
              body: { type: 'ViaName', roleName: REALTOR_ROLE_NAME, userId },
              headers: {
                Authorization: `Bearer ${authClient.getAccessToken()}`,
                ClientId: CLIENT_ID,
              },
            });

            if (assignRoleResponse.error) {
              return { error: assignRoleResponse.error as FetchBaseQueryError };
            }
          }

          return { data: undefined };
        } catch (e) {
          return { error: prepareError(e) };
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(signIn());
          dispatch(setRedirectUrl(null));
        });
      },
    }),
    // --------- Sign Up
    createSignUpRequest: build.mutation<CreateSignInRequestResponse, CreateSignUpRequestPayload>({
      queryFn: async params => {
        const { isViaPhone = true } = params;
        try {
          const data = await authClient.initSignUpRequest({
            firstName: params.firstName,
            lastName: params.lastName,
            email: params.emailAddress,
            phoneNumber: params.phoneNumber,
            companyId: params.companyId,
            roleName: AuthUserRoles.Realtor,
            userType: AuthUserTypes.RealtorUser,
            type: isViaPhone ? AuthInitiateType.ViaPhone : AuthInitiateType.ViaEmail,
            redirectUrl: params.redirectUrl,
          });
          return { data };
        } catch (e) {
          return { error: prepareError(e) };
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(
          ({ data: { expirationDate, confirmationUrl, confirmationCode, requestId } }) => {
            dispatch(
              setCreateRequestAuthData({
                requestId,
                confirmationCode,
                expirationDate,
                ...(confirmationUrl && { redirectUrl: confirmationUrl }),
              }),
            );
          },
        );
      },
    }),
    // ---- Other
    getCompanyId: build.query<CompanyIdResponse, void>({
      query: () => ({
        url: 'tenant',
        method: 'GET',
        params: { domainName: getDomainName() },
      }),
    }),
    getUser: build.query<GetUserResponse, GetUserPayload>({
      query: ({ companyId, userId }) => {
        const queryParams = new URLSearchParams();
        ['role', 'roleAssignments', 'claims'].forEach(param => {
          queryParams.append('include', param);
        });
        queryParams.append('isDeleted', 'false');

        return {
          url: `companies/${companyId}/users/${userId}`,
          method: 'GET',
          headers: prepareHeaders(new Headers()),
          params: queryParams,
        };
      },
      transformResponse: (response: GetUserResponseFromServer): GetUserResponse => ({
        ...response,
        fullName: `${response.firstName} ${response.lastName}`,
      }),
    }),
  }),
});

export const {
  // Sign In / Sign Up
  useCreateSignInRequestMutation,
  useGenerateSignInTokenMutation,
  useCreateSignUpRequestMutation,
  // Other
  useGetCompanyIdQuery,
  useGetUserQuery,
} = authAPI;

export default authAPI;
